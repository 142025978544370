body, html, #root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.controls {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: black;
  opacity: 0.5;
  color: white;
  padding: 5px;
}

p {
  margin: 5px;
  font-family: monospace;
  font-size: 12px;
  letter-spacing: 0.75px;
}